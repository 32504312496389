import { useQuery } from "@tanstack/react-query";
import { StatusService } from "../client";
import React from "react";
import { Box, Container, Heading, Image, Flex, Text, Badge, Spinner } from "@chakra-ui/react";
import { useStore } from "../zustand/store";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import { getModuleTitle } from "../utils/GetCurrentTitle";
import { useGetModuleIdTitle } from "src/utils/useGetModuleIdTitle";

//@ts-ignore
import logoImage from "../resources/logo.webp";

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const statusQuery = useQuery(["get", "Status"], () => StatusService.getStatus(), {});
  
  const auth = useAuth();

  const { t } = useTranslation("common");
  const reset = useStore((state) => state.reset);

  const currentModuleId = useGetModuleIdTitle();
  const isRootModule = getModuleTitle() === "home";
  const currentModuleTitle = isRootModule
    ? t("welcomeEmployee", { name: auth?.user?.profile.name })
    : getModuleTitle()
    ? t(getModuleTitle(), { ns: "routes" })
    : currentModuleId;

  return (
    <Flex direction="column" minH="100vh">
      <Container maxW="container.xl" flex="1">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={6}>
          <Heading fontWeight="light" size={{ base: "md", md: "xl" }}>
            {currentModuleTitle}
            {/*Welcome {employeeData ? employeeData.name : <Spinner size="lg" />}*/}
          </Heading>
          <Box>
            <Flex direction="column" alignItems="flex-end">
              <Box display="flex" alignItems="center">
                  <Image
                    src={logoImage}
                    alt="Logo"
                    w="300px"
                    // maxH={{ base: "50px", md: "100px" }}
                    h={"75px"}
                    objectFit="contain"
                    mb={"8px"}
                    mt={"8px"}
                  />
              </Box>
              <Flex direction="row" alignItems="flex-end">
                <Box display="flex" alignItems="flex-end" ml={4}>
                  <Badge
                    colorScheme={"red"}
                    fontWeight="bold"
                    cursor="pointer"
                    mr={2}
                    px={2}
                    py={1}
                    borderRadius="md"
                    onClick={() => {
                      reset();
                      auth.signoutRedirect();
                    }}
                  >
                    <Flex alignItems="center">{t("logout")}</Flex>
                  </Badge>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
        {children}
      </Container>
      <Flex
        position="relative"
        bottom={0}
        right={0}
        p={2}
        justify={{ base: "center", md: "flex-end" }}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        {statusQuery.data ? (
          <Text color="#909193" fontWeight="light">
            {t("version")} {statusQuery.data.version}
          </Text>
        ) : (
          <Box display="flex" alignItems="center">
            <Text color="#909193" fontWeight="light">
              {t("version")}
            </Text>
            <Spinner color="#909193" size={"md"} ml={1} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
