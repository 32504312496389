const subdomain = window.location.hostname.split(".")[0];

const vars = {
  maxImageSize: 8 * 1024 * 1024, // 8 MB
  apiUrl: process.env.REACT_APP_FORCE_API_URL
    ? process.env.REACT_APP_API_URL
    : subdomain === "staging-app"
    ? `https://staging-api.kundenportal.de:444/api`
    : `https://api-${subdomain}.kundenportal.de/api`,
};

export default vars;
