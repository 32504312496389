import { useQuery, QueryFunction } from "@tanstack/react-query";
import {
  EmployeeService, InterimOrderDataService, OrderDataService,
} from "src/client";
import { useTranslation } from "react-i18next";

export const useGetModuleIdTitle = <T>() => {
  const { module_type, id } = getModuleTypeAndId();
  let queryFn: QueryFunction<T, string[]>;
  let queryKey: string[];
  const { t } = useTranslation("id_routes");

  switch (module_type) {
    case "employees":
      queryFn = () => EmployeeService.getEmployee({ id }) as unknown as Promise<T>;
      break;
    case "order-data":
      queryFn = () => OrderDataService.getOrderData({ id }) as unknown as Promise<T>;
      break;
    case "interim-order-data":
      queryFn = () => InterimOrderDataService.getInterimOrderData({ id }) as unknown as Promise<T>;
      break;
    default:
      queryFn = () => Promise.reject("Invalid module_type");
      queryKey = [];
  }

  queryKey = [module_type, id];

  const { data, isLoading, error } = useQuery(queryKey, queryFn);

  if (isLoading) {
    return t("loading");
  }

  if (error) {
    console.error("Error fetching data:", error);
    // return t("errorFetchingData");
    return "";
  }

  switch (module_type) {
    case "employees":
      //@ts-ignore
      return t(module_type) + (data?.name || "");
    case "order-data":
      //@ts-ignore
      return "";
    case "interim-order-data":
      //@ts-ignore
      return "";
    default:
      return "";
  }
};

function getModuleTypeAndId() {
  const url = window.location.pathname;
  const segments = url.split("/");
  
  let moduleTypeIndex = segments.findIndex(segment => segment !== '');
  let moduleType = segments[moduleTypeIndex];
  let id = segments[moduleTypeIndex + 1];

  if(id === undefined) {
    id = moduleType;
    moduleType = "";
  }

  return { module_type: moduleType, id: id };
}
