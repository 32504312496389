/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResponse } from '../models/ListResponse';
import type { OrderData } from '../models/OrderData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderDataService {

    /**
     * Get a list of `OrderData`s
     * @returns any A list of `OrderData`s.
     * @throws ApiError
     */
    public static listOrderDatas({
page,
size,
filter,
sort,
searchTerm,
}: {
/**
 * Page number
 */
page?: number,
/**
 * Number of items per page
 */
size?: number,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
/**
 * Sorts the results by the given field and order. The format is field:order. The order can be asc or desc. Multiple fields can be sorted by using a comma separated list.
 */
sort?: string,
/**
 * Seach Term for full text search. Whether supported and which fields are included depends on the model.
 */
searchTerm?: string,
}): CancelablePromise<ListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order-data',
            query: {
                'page': page,
                'size': size,
                'filter': filter,
                'sort': sort,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * Get a list of restricted `OrderData`s
     * @returns any A list of `OrderData`s.
     * @throws ApiError
     */
    public static listRestrictedOrderDatas({
page,
size,
filter,
sort,
searchTerm,
}: {
/**
 * Page number
 */
page?: number,
/**
 * Number of items per page
 */
size?: number,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
/**
 * Sorts the results by the given field and order. The format is field:order. The order can be asc or desc. Multiple fields can be sorted by using a comma separated list.
 */
sort?: string,
/**
 * Seach Term for full text search. Whether supported and which fields are included depends on the model.
 */
searchTerm?: string,
}): CancelablePromise<ListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order-data/restricted',
            query: {
                'page': page,
                'size': size,
                'filter': filter,
                'sort': sort,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * Upload an excel file to receive an full result excel file
     * @returns binary A processed Excel file
     * @throws ApiError
     */
    public static uploadExcel({
requestBody,
}: {
requestBody: any,
}): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order-data/excel',
            body: requestBody,
        });
    }

    /**
     * Send a list of restricted `OrderData`s as Email
     * @returns any Send Emails process finished successfully
     * @throws ApiError
     */
    public static sendEmails({
page,
size,
filter,
sort,
searchTerm,
}: {
/**
 * Page number
 */
page?: number,
/**
 * Number of items per page
 */
size?: number,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
/**
 * Sorts the results by the given field and order. The format is field:order. The order can be asc or desc. Multiple fields can be sorted by using a comma separated list.
 */
sort?: string,
/**
 * Seach Term for full text search. Whether supported and which fields are included depends on the model.
 */
searchTerm?: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order-data/restricted/sendEmails',
            query: {
                'page': page,
                'size': size,
                'filter': filter,
                'sort': sort,
                'searchTerm': searchTerm,
            },
            errors: {
                409: `Send Emails process failed`,
            },
        });
    }

    /**
     * Get an `OrderData` by ID
     * @returns OrderData A single `OrderData`.
     * @throws ApiError
     */
    public static getOrderData({
id,
}: {
/**
 * ID
 */
id: string,
}): CancelablePromise<OrderData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order-data/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get an restricted `OrderData` by ID
     * @returns OrderData A single `OrderData`.
     * @throws ApiError
     */
    public static getRestrictedOrderData({
id,
}: {
/**
 * ID
 */
id: string,
}): CancelablePromise<OrderData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order-data/{id}/restricted',
            path: {
                'id': id,
            },
        });
    }

}
