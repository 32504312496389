import { Fragment, Suspense, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import reportWebVitals from "./reportWebVitals";
import { privateRoutes, publicRoutes } from "./routes";
import DefaultLayout from "./layouts/DefaultLayout";
import SecurityLayout from "./layouts/SecurityLayout";
import { useToast } from "@chakra-ui/react";
import { PreviousStateProvider } from "./contexts/PreviousStateContext";
import Fonts from "./theme/foundations/fonts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LicenseInfo } from "@mui/x-license-pro";
import { OpenAPI } from "./client";
import LoadingSpinner from "./components/LoadingSpinner";
import { MutationCache } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ChakraWrapper } from "./contexts/ChakraContext";
import "./config/i18next";
import vars from "./config/vars";
import { AuthProvider, useAuth } from "react-oidc-context";
import oidcConfig from './config/auth/oicd.config';
import useSnackError from "./hooks/useSnackError";
import { AbilityContext } from "./contexts/AbilityContext";

export default function App() {
  const toast = useToast();
  const snackError = useSnackError();
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

  // set api url
  OpenAPI.BASE = vars.apiUrl;

  const mutationCache = new MutationCache({
    onError: (error) => {},
    onSuccess: (data, variables, context, mutation) => {
      const meta = mutation.options.meta;
      if(meta?.customSuccess) {
        return;
      }else{
        toast({
          title: meta?.toastTitle || "Success",
          description: meta?.toastDescription || "Changes saved successfully",
          status: "success",
          position: "bottom-left",
        });
      }
    },
  });

  const queryClient = new QueryClient({
    mutationCache,
    defaultOptions: {
      mutations: {
        onError: (error) => {
          snackError(error);
        },
      },
      useErrorBoundary: true,
    },
  });

  const isArrayOfArrays = (input) =>
  Array.isArray(input) && input.every(Array.isArray);

  const CheckAbility = ({ requiredAbility, children }) => {
    const ability = useContext(AbilityContext);
    const auth = useAuth();
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);

    useEffect(() => {
      if (auth.isAuthenticated && ability) {
        setPermissionsLoaded(true);
      }
    }, [auth.isAuthenticated, ability]);
  
    if (!permissionsLoaded) {
      return <></>;
    }

    if(isArrayOfArrays(requiredAbility)){
      const hasAllRequiredAbilities = requiredAbility.every((abilityPair) =>
        ability.can(abilityPair[0], abilityPair[1], abilityPair[2] || undefined)
      );
      return hasAllRequiredAbilities ? children : <Navigate to="/" replace />;
    }else{
      const [action, subject, field] = requiredAbility;
      return ability.can(action, subject, field | undefined) ? children : <Navigate to="/" replace />;
    }

  }

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {process.env.REACT_APP_NODE_ENV === "development" ? <ReactQueryDevtools initialIsOpen={false} /> : ""}
        <ChakraWrapper>
          <Fonts />
          <PreviousStateProvider>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                {privateRoutes.map((route) => {
                  const Page = route.component;
                  let Layout = DefaultLayout;

                  if (route.layout === null) {
                    Layout = Fragment;
                  } else if (route.layout) {
                    Layout = route.layout;
                  }

                  if(route.requiredAbility){
                    return (
                      <Route
                        key={uuid()}
                        path={route.path}
                        element={
                            <AuthProvider {...oidcConfig}>
                                <SecurityLayout>
                                  <CheckAbility requiredAbility={route.requiredAbility}>
                                    <Layout>
                                      <Page />
                                    </Layout>
                                  </CheckAbility>
                                </SecurityLayout>
                            </AuthProvider>
                        }
                      />
                    );
                  }else{
                    return (
                      <Route
                        key={uuid()}
                        path={route.path}
                        element={
                          <AuthProvider {...oidcConfig}>
                            <SecurityLayout>
                              <Layout>
                                <Page />
                              </Layout>
                            </SecurityLayout>
                          </AuthProvider>
                        }
                      />
                    );
                  }

                })}
                {publicRoutes.map((route) => {
                  const Page = route.component;
                  let Layout = DefaultLayout;

                  if (route.layout === null) {
                    Layout = Fragment;
                  } else if (route.layout) {
                    Layout = route.layout;
                  }

                  return (
                    <Route
                      key={uuid()}
                      path={route.path}
                      element={
                        <AuthProvider {...oidcConfig}>
                          <Layout>
                            <Page />
                          </Layout>
                        </AuthProvider>
                      }
                    />
                  );
                })}
              </Routes>
            </Suspense>
          </PreviousStateProvider>
        </ChakraWrapper>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals(console.log);
