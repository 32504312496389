import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { GridPinnedColumns, GridSortModel } from "@mui/x-data-grid-premium";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { Settings } from "../client";
import { UiConfig } from "../types/UiConfig.type";
import { initialImagesUiConfig, initialThemeUiConfig } from "../config/initialUiConfig";

export type GridConfig = {
  entityType: string;
  sortModel: GridSortModel;
  columnVisibilityModel: GridColumnVisibilityModel;
  pinnedColumns: GridPinnedColumns;
};

export type Store = {
  accessToken?: string | undefined;
  setAccessToken: (accessToken: string | undefined) => void;
  gridConfigs: GridConfig[];
  getGridConfig: (entityType: string) => GridConfig | undefined;
  updateGridConfig: (entityType: string, newGridConfig: GridConfig) => void;

  uiConfig: {
    theme: UiConfig["theme"];
    images: UiConfig["images"];
  };
  setUiConfig: (uiConfig: { theme?: UiConfig["theme"]; images?: UiConfig["images"] }) => void;

  settings?: Settings;
  setSettings: (settings: Settings) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  reset: () => void;
};

// no TS error when persist wraps devtools, but then data does not rehydrate after refreshing
export const useStore = create<Store>(
  // @ts-ignore
  devtools(
    // @ts-ignore
    persist(
      (set, get) => ({
        accessToken: undefined,
        setAccessToken: (accessToken: string | undefined) => {
          if(accessToken === undefined){
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_AUTH_COOKIE_DOMAIN}; path=/;`;
          }else{
            document.cookie = `token=${accessToken}; path=/; domain=${process.env.REACT_APP_AUTH_COOKIE_DOMAIN}; SameSite=Strict; Secure`;
          }
          set({ accessToken: accessToken });
        },

        gridConfigs: [],

        getGridConfig: (entityType: string) => {
          return get().gridConfigs.find((gridConfig) => gridConfig.entityType === entityType);
        },

        updateGridConfig: (entityType: string, newGridConfig: GridConfig) => {
          const index = get().gridConfigs.findIndex((gridConfig) => gridConfig.entityType === entityType);

          if (index === -1) {
            set((state) => ({
              gridConfigs: [...state.gridConfigs, newGridConfig],
            }));
          } else {
            set((state) => {
              const newGridConfigs = [...state.gridConfigs];
              newGridConfigs[index] = newGridConfig;
              return { gridConfigs: newGridConfigs };
            });
          }
        },

        uiConfig: {
          theme: initialThemeUiConfig,
          images: initialImagesUiConfig,
        },

        setUiConfig: (uiConfig: { theme?: UiConfig["theme"]; images?: UiConfig["images"] }) => {
          set((state) => ({
            uiConfig: {
              ...state.uiConfig,
              ...uiConfig,
            },
          }));
        },

        settings: undefined,
        setSettings: (settings: Settings) => {
          set({ settings });
        },

        isLoading: false,
        setIsLoading: (isLoading: boolean) => {
          set({ isLoading });
        },

        reset: () => {
          // @ts-ignore
          useStore.persist.clearStorage();

          // delete token cookie
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_AUTH_COOKIE_DOMAIN}; path=/;`;

          // delete all other cookies
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
          
          // @ts-ignore
          set((state) => ({ ...state, auth: undefined, gridConfigs: [] }), true );

        },
      }),
      {
        name: "kundenportal_store",
        getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
      }
    ),
    { name: "Kundenportal Store" }
  )
);
