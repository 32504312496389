import { lazy } from "react";

// Pages
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));

// Layouts
const InvalidLayout = lazy(() => import("./layouts/InvalidLayout"));

// Employee Pages
const EmployeeListPage = lazy(() => import("./pages/Employee/pages/EmployeeListPage"));
const EmployeeDetailsPage = lazy(() => import("./pages/Employee/pages/EmployeeDetailsPage"));

// Customization Pages
const CustomizationThemePage = lazy(() => import("./pages/Customization/pages/CustomizationThemePage"));
const CustomizationImagePage = lazy(() => import("./pages/Customization/pages/CustomizationImagePage"));
const CustomizationLoginPage = lazy(() => import("./pages/Customization/pages/CustomizationLoginPage"));

const SettingsPage = lazy(() => import("./pages/Settings/pages/SettingsPage"));

// OrderData Pages
const OrderDataListPage = lazy(() => import("./pages/OrderData/pages/OrderDataListPage"));
const OrderDataDetailsPage = lazy(() => import("./pages/OrderData/pages/OrderDataDetailsPage"));

// InterimOrderData Pages
const InterimOrderDataListPage = lazy(() => import("./pages/InterimOrderData/pages/InterimOrderDataListPage"));
const InterimOrderDataDetailsPage = lazy(() => import("./pages/InterimOrderData/pages/InterimOrderDataDetailsPage"));

// RestrictedOrderData Pages
const RestrictedOrderDataListPage = lazy(() => import("./pages/RestrictedOrderData/pages/OrderDataListPage"));
const RestrictedOrderDataDetailsPage = lazy(() => import("./pages/RestrictedOrderData/pages/OrderDataDetailsPage"));

// RestrictedInterimOrderData Pages
const RestrictedInterimOrderDataListPage = lazy(() => import("./pages/RestrictedInterimOrderData/pages/InterimOrderDataListPage"));
const RestrictedInterimOrderDataDetailsPage = lazy(() => import("./pages/RestrictedInterimOrderData/pages/InterimOrderDataDetailsPage"));

export const routePaths = {
  home: "/",
  login: "/login",
  employees: "/employees",
  employeeDetails: (employeeId) => `/employees/${employeeId}`,
  customizationTheme: "/customization/theme",
  customizationImage: "/customization/images",
  customizationLogin: "/customization/login",
  settings: "/settings",
  orderData: "/order-data",
  orderDataDetails: (centerraOrderNo) => `/order-data/${centerraOrderNo}`,
  interimOrderData: "/interim-order-data",
  interimOrderDataDetails: (d_orderNo) => `/interim-order-data/${d_orderNo}`,
  restrictedOrderData: "/restricted-order-data",
  restrictedOrderDataDetails: (centerraOrderNo) => `/restricted-order-data/${centerraOrderNo}`,
  restrictedInterimOrderData: "/restricted-interim-order-data",
  restrictedInterimOrderDataDetails: (d_orderNo) => `/restricted-interim-order-data/${d_orderNo}`,
};

const publicRoutes = [
  { path: routePaths.login, component: Login, layout: Login },
  { path: "*", component: Home, layout: InvalidLayout }
];

const privateRoutes = [
  { path: "/", component: Home },
  { path: routePaths.employees, component: EmployeeListPage, requiredAbility: ["view","FE_Employee"] },
  {
    path: routePaths.employeeDetails(":id"),
    component: EmployeeDetailsPage,
    requiredAbility: [["view","FE_Employee"],["view","Employee"]]
  },
  { path: routePaths.customizationTheme, component: CustomizationThemePage, requiredAbility: [["view","FE_Customization"],["view","Customization"]] },
  { path: routePaths.customizationImage, component: CustomizationImagePage, requiredAbility: [["view","FE_Customization"],["view","Customization"]] },
  { path: routePaths.customizationLogin, component: CustomizationLoginPage, requiredAbility: [["view","FE_Customization"],["view","Customization"]] },
  { path: routePaths.settings, component: SettingsPage, requiredAbility: [["view","FE_Settings"],["view","Settings"]] },
  { path: routePaths.orderData, component: OrderDataListPage, requiredAbility: ["view","FE_OrderData"] },
  {
    path: routePaths.orderDataDetails(":centerraOrderNo"),
    component: OrderDataDetailsPage,
    requiredAbility: [["view","FE_OrderData"],["view","OrderData"]]
  },
  { path: routePaths.interimOrderData, component: InterimOrderDataListPage, requiredAbility: ["view","FE_InterimOrderData"] },
  {
    path: routePaths.interimOrderDataDetails(":d_orderNo"),
    component: InterimOrderDataDetailsPage,
    requiredAbility: [["view","FE_InterimOrderData"],["view","InterimOrderData"]]
  },
  { path: routePaths.restrictedOrderData, component: RestrictedOrderDataListPage, requiredAbility: ["view","FE_RestrictedOrderData"] },
  {
    path: routePaths.restrictedOrderDataDetails(":centerraOrderNo"),
    component: RestrictedOrderDataDetailsPage,
    requiredAbility: [["view","FE_RestrictedOrderData"],["view","RestrictedOrderData"]]
  },
  { path: routePaths.restrictedInterimOrderData, component: RestrictedInterimOrderDataListPage, requiredAbility: ["view","FE_RestrictedInterimOrderData"] },
  {
    path: routePaths.restrictedInterimOrderDataDetails(":d_orderNo"),
    component: RestrictedInterimOrderDataDetailsPage,
    requiredAbility: [["view","FE_RestrictedInterimOrderData"],["view","RestrictedInterimOrderData"]]
  },
];

export { publicRoutes, privateRoutes };
